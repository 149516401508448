<template>
    <div class="container mx-auto sm:px-6 lg:px-8 h-screen flex items-center justify-center">
        <div class="flex flex-col bg-gray-200 p-16 rounded-md">
            <h2 class="text-9xl font-semibold text-gray-500">404</h2>
            <div class="text-xl">Страница не найдена.<br/>Напишите, мы ее обязательно найдем</div>
            <div class="text-xl"><a href="https://t.me/bigblackdot" class="text-blue-500">telegram</a> разработчика</div>
        </div>
    </div>
</template>
<script></script>
<style scoped>
</style>
