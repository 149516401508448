import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFoundView.vue'

const routes = [
    {
        path: '/:branch?',
        // path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/:notFound',
        component: NotFound
    }//,
    // {
    //     path: '/:branch/:section?/:tag?/:sub_tag?',
    //     component: HomeView
    // },
    // {
    //     path: '/:branch/:pathMatch(.*)*',
    //     component: HomeView
    // }//,
    // {
    //     path: '/:pathMatch(.*)*',
    //     component: HomeView
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
