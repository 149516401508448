<template>
    <header class="bg-amber-300" style="background-image: url('https://sun9-40.userapi.com/impg/UMAJA77_LnZ90o_qePG52gSqPp9B9ccXewMfeQ/dwNt5OlbUcc.jpg?size=1280x393&quality=96&sign=11c79e82515587a38b5de6c0726f5d55&type=album'); background-size: cover; background-position: center">
        <div class="container mx-auto py-4 px-16">
            <section class="nav-section flex items-center justify-between" :ref="'/' + branch.slug" :path="'/' + branch.slug">
                <div class="flex items-center">
                    <!-- <div class=""><a href="#sales">АКЦИИ</a></div> -->
                    <div class="pr-6"><a href="#menu">МЕНЮ</a></div>
                    <div class="px-6"><a href="#delivery">ДОСТАВКА</a></div>
                    <div class="px-6"><a href="#delivery">КОНТАКТЫ</a></div>
                </div>
                <div class="flex items-center">
                    <div class="pr-8 flex cursor-pointer branches">
                        <div class="rounded-md border border-red-500 px-4 py-2 text-sm"
                        v-for="abranch in branches" :key="abranch.id"
                        :class="[ branch?.id === abranch.id ? 'bg-red-700 text-white font-light' : 'font-semibold' ]"
                        @click="setBranch(abranch)">{{ abranch.title }}</div>
                    </div>
                    <div class="pr-8">
                        <div class="rounded-md border border-amber-500 px-5 py-2 font-semibold text-sm">
                            {{ branch.phone }}
                        </div>
                    </div>
                    <div class="p-2 rounded-full border border-amber-500 relative">
                        <a href="#basket">
                            <img src="https://img.icons8.com/?size=512&id=G7PELQpF8j6g&format=png" class="h-5 aspect-square" alt="Перейти к корзине"/>
                            <div class="absolute top-0 left-0">
                                <div v-for="item in basket" :key="item.id">{{ item.name }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <div class="flex justify-between items-center" style="height: 450px">
                <div class="">
                    <div class="text-4xl">
                        <div class="text-left text-3xl uppercase">
                            <img src='@/assets/logo.png' class="h-24 ml-[-20px]" alt="Омлет, доставка еды"/>
                            <!-- <div class="font-semibold pt-4">ДОСТАВКА ЕДЫ</div> -->
                            <div class="text-left pt-2 pl-2">
                                <h1 class="text-sm font-bold">Доставка еды, пиццы, суши</h1>
                            </div>
                        </div>
                        <div class="py-8 font-bold ">
                            <div class="text-left pb-4 uppercase text-3xl">
                                С любовью готовим,
                            </div>
                            <div class="text-left uppercase text-3xl">
                                с удовольствием доставляем!
                            </div>
                        </div>
                    </div>
                    <div class="flex pt-8">
                        <a href="#menu">
                            <div class="py-2 pr-8 pl-6 bg-white rounded-lg font-semibold flex items-center">
                                <img src='/favicon.ico' class="h-4 w-4 mb-1 mr-2" alt="Перейти к меню"/> ХОЧУ ЕСТЬ
                            </div>
                        </a>
                    </div>
                </div>
                <div></div>
            </div>
            <div class="flex items-center justify-center">
                <div class="flex items-center pr-16">
                    <div class="h-16 aspect-square">
                        <img src="https://img.icons8.com/?size=512&id=24292&format=png" class="h-full" alt="Готовим вкусно"/>
                    </div>
                    <div class="pl-4 pr-4 uppercase text-left">
                        <div class="font-semibold">ВКУСНО</div>
                        <div class="">Готовим</div>
                    </div>
                </div>
                <div class="flex items-center pr-16">
                    <div class="h-16 aspect-square">
                        <img src="https://img.icons8.com/?size=512&id=SIWc7qYHKuvW&format=png" class="h-full" alt="Упаковываем чисто"/>
                    </div>
                    <div class="pl-4 text-left">
                        <div class="font-semibold">ЧИСТО</div>
                        <div class="">Упаковываем</div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="h-16 aspect-square">
                        <img src="https://img.icons8.com/?size=512&id=59775&format=png" class="h-full" alt="Быстро доставляем"/>
                    </div>
                    <div class="pl-4 text-left">
                        <div class="font-semibold">БЫСТРО</div>
                        <div class="">Доставляем</div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <main class="container mx-auto py-4 px-16">
        <section class="py-8">
            <div class=" py-8">
                <h2 class="text-xl font-semibold uppercase">Популярные комбо</h2>
                <div class="pt-2">вкуснейшие комбо по выгодным ценам, специально подобраны по вкусу</div>
            </div>
            <div class="flex justify-between">
                <div v-if="combos !== null" class="flex flex-wrap justify-between w-full">
                    <div class="flex justify-center  flex-wrap w-full">
                        <ItemContainer v-for="combo in combos" :key="combo.id" :item="combo" is_combo=1 class="w-1/5 p-2"/>
                    </div>
                    <div class="flex justify-center w-full">
                        <div class="rounded-md border border-amber-700 py-2 px-8 m-4 bg-amber-400 font-semibold text-amber-900 cursor-pointer" @click="getCombos()">Показать еще</div>
                    </div>
                </div>
                <div v-else class="flex flex-wrap justify-between w-full">
                    <ItemContainer is_loading class="w-1/5 p-4" />
                    <ItemContainer is_loading class="w-1/5 p-4" />
                    <ItemContainer is_loading class="w-1/5 p-4" />
                    <ItemContainer is_loading class="w-1/5 p-4" />
                    <ItemContainer is_loading class="w-1/5 p-4" />
                </div>
            </div>
        </section>
        <section id="menu" class="nav-section py-8" ref="menu" :path="'/' + branch.slug + '/menu/' + selected_tag?.slug">
            <div class=" py-8">
                <h2 class="text-xl font-semibold uppercase">Наше меню</h2>
                <div class="pt-2">пицца, суши, горячие блюда, напитки, закуски, специальные блюда</div>
            </div>
            <div v-if="tags?.length > 0" class="flex justify-center">
                <TagContainer v-for="tag in tags" :key="tag.id" :item="tag" class="mx-2 bg-white cursor-pointer" @click="selectTag(tag.id)"
                    :class="[selected_tag?.id == tag.id ? 'border-amber-700 text-amber-700 bg-gray-100 font-bold' : '']"/>
            </div>
            <div v-else class="flex justify-center">
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
                <SkeletonLoader class="mx-2 h-32 w-28 rounded-2xl" />
            </div>
        </section>
        <section class="py-8">
            <h2 class="text-xl font-semibold uppercase py-8">{{ selected_tag?.emoji }} {{ selected_tag?.name }} {{ selected_tag?.emoji }}</h2>
            <div>
                <div v-if="sub_tags !== null" class="flex flex-wrap justify-center items-center mb-8 h-16">
                    <div class="border border-amber-400 rounded-md px-3 py-1 m-2 cursor-pointer"
                    @click="selectSubTag('all')"
                    :class="[selected_sub_tag?.id == 'all' ? 'bg-red-700 text-white border-red-700' : '']">ВСЕ</div>
                    <div v-for="sub_tag in sub_tags" :key="sub_tag.id" class="border border-amber-400 rounded-md px-3 py-1 m-2 cursor-pointer"
                    @click="selectSubTag(sub_tag.id)"
                    :class="[selected_sub_tag?.id == sub_tag.id ? 'bg-red-700 text-white border-red-500' : '']">{{ sub_tag.name }}</div>
                </div>
                <div v-else class="flex flex-wrap justify-center items-center mb-8 h-16">
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                    <SkeletonLoader class="m-2 border border-gray-400 h-10 w-20 rounded-md" />
                </div>
                <div v-if="selected_tag !== null" class="flex flex-wrap justify-around">
                    <ItemContainer v-for="meal in meals" :key="meal.id" :item="meal" class="w-1/5 p-2"/>
                </div>
                <div v-else class="flex flex-wrap justify-around">
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>

                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                    <ItemContainer is_loading class="w-1/5 p-4"/>
                </div>

                <div v-if="meals_meta !==  null && selected_sub_tag.id === 'all'">
                    <div v-if="meals_meta?.last_page !==  meals_meta?.current_page" @click="getMoreMealsByTag()" class="flex justify-center">
                        <div class="rounded-md border border-amber-700 py-2 px-8 m-4 bg-amber-400 font-semibold text-amber-900 cursor-pointer">Показать еще</div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="basket_stage < 3" class="py-4 rounded-md mb-16" id="basket">
            <div v-if="$store.getters.basketItems.length > 0" class="px-16 bg-amber-300" >
                <div v-if="basket_stage == 1">
                    <h2 class="text-xl font-semibold uppercase py-8">В вашем заказе</h2>
                    <div class="flex flex-wrap justify-center">
                        <ItemContainer v-for="item in $store.getters.basketItems" :key="item.id" :item="item" :is_combo="item.is_combo" class="w-1/5 p-4"/>
                    </div>
                    <h2 class="text-xl font-semibold uppercase py-8">Общая сумма: {{ $store.getters.basketSumm }} <sup>KZT</sup></h2>
                    <div class="flex w-full justify-center pb-8">
                        <div class="py-4 px-8 text-xl bg-yellow-100 hover:bg-yellow-200 border border-yellow-100 rounded-lg cursor-pointer" @click="basket_stage = 2">{{ emoji[Math.floor(Math.random()*emoji.length)] }} продолжить</div>
                    </div>
                </div>
                <div v-if="basket_stage == 2">
                    <h2 class="text-xl font-semibold uppercase py-8">Оплата и доставка</h2>
                    <div class="flex flex-col items-center">
                        <div class="items-start w-2/3">
                            <div class="text-left">
                                <div class="font-semibold py-2">Контактный телефон</div>
                                <div class="">
                                    <input type="text" v-model="user.phone" ref="user_phone" class="rounded-md bg-white border-2 p-2 w-2/3" placeholder="+7 747 1092682"/>
                                </div>
                            </div>
                            <div class="text-left">
                                <div class="font-semibold pt-8">Адрес доставки</div>
                                <div class="flex">
                                    <div class="w-1/3">
                                        <div class="py-2">Улица</div>
                                        <input type="text" v-model="address.street" ref="address_street" class="rounded-md bg-white border-2 p-2 w-11/12" placeholder="Айманова"/>
                                    </div>
                                    <div class="">
                                        <div class="py-2">Дом</div>
                                        <input type="text" v-model="address.building" ref="address_building" class="rounded-md bg-white border-2 p-2 w-2/3" placeholder="154/2"/>
                                    </div>
                                    <div class="">
                                        <div class="py-2">Кв./Офис</div>
                                        <input type="text" class="rounded-md bg-white border-2 p-2 w-2/3" placeholder="77"/>
                                    </div>
                                    <div class="">
                                        <div class="py-2">Подъезд</div>
                                        <input type="text" class="rounded-md bg-white border-2 p-2 w-2/3" placeholder="1"/>
                                    </div>
                                    <div class="">
                                        <div class="py-2">Этаж</div>
                                        <input type="text" class="rounded-md bg-white border-2 p-2 w-2/3" placeholder="1"/>
                                    </div>
                                    <div class="">
                                        <div class="py-2"><nobr>По звонку</nobr></div>
                                        <InputSwitch class="mt-2" v-model="address.has_intercom"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full justify-center py-16 items-center">
                        <div class="py-2 bg-amber-300 border border-amber-300 rounded-lg w-1/3 cursor-pointer" @click="basket_stage = 1">&larr; в заказе</div>
                        <div class="py-4 px-8 text-xl bg-yellow-100 hover:bg-yellow-200 border border-yellow-100 rounded-lg w-1/3 cursor-pointer" @click="sendOrder()">{{ emoji[Math.floor(Math.random()*emoji.length)] }} Отправить заказ</div>
                    </div>
                </div>
            </div>
            <div v-else class="py-16 bg-gray-200 flex flex-col justify-center items-center">
                <div class=" text-9xl bg-gray-300 rounded-full w-48 h-48 flex items-center justify-center">🤷</div>
                <div class=" text-2xl italic pt-8">В заказе пусто</div>
                <div class=" text-xl italic p-4">Добавьте блюда к заказу из нашего меню, чтобы сделать ваш обед или ужин по-настоящему впечатляющим! Мы готовы порадовать вас свежими и вкусными блюдами, которые точно угодят вашему вкусу. Не упустите шанс попробовать наши лучшие деликатесы прямо сейчас - просто выберите то, что вам нравится, и наслаждайтесь!</div>
            </div>
            <div class="p-16 bg-yellow-200 flex flex-col justify-center items-center italic text-sm text-black">
                У нас разнообразное меню на любой вкус! Независимо от ваших предпочтений и настроения, мы готовы угодить вашим желаниям. От классических блюд до современных экспериментов - у нас есть всё, чтобы удовлетворить ваши запросы. Выбирайте то, что вам по душе, и наслаждайтесь кулинарным разнообразием с нами!
            </div>
        </section>
        <section v-if="basket_stage == 3" class="py-4 px-16 bg-amber-300 rounded-md mb-16">
            <h2 class="text-xl font-semibold uppercase py-8">Отправляем заказ...</h2>
        </section>
        <section v-if="basket_stage == 4" class="py-4 px-16 bg-amber-300 rounded-md mb-16">
            <h2 class="text-xl font-semibold uppercase py-8">Ваш заказ отправлен в Омлет №{{ new_order_id }} </h2>
        </section>
        <section class="pt-8 pb-16 px-16 bg-amber-100 rounded-md mb-16">
            <h2 class="text-xl font-semibold uppercase py-8 text-center">Омлет - не просто доставка</h2>
            <div class="flex items-center">
                <div class="mr-8 w-1/3">
                    <img src="https://lh3.googleusercontent.com/p/AF1QipPYC7ltiTxUOOzUv9KJUaIUcJd3yGC9XLDNswTa=s680-w680-h510"  class="rounded-md" alt="Омлет, доставка еды"/>
                    <!-- <img src="https://lh3.googleusercontent.com/p/AF1QipMwaAlKry4fjfo5ug6LRBA4KgX1tYHvLiYt0AJ5=s680-w680-h510"  class="rounded-md"/> -->
                </div>
                <div class="w-2/3 text-md text-left">
                    <div class="pb-4">
                        Доставка еды "Омлет" — пионер в мире доставки еды, начавший свою деятельность в 2013 году. С тех пор компания утвердила себя как лидер в индустрии, предлагая клиентам непревзойденное кулинарное путешествие, которое начинается с удобного онлайн-заказа.
                    </div>
                    <div class="pb-4">
                        С момента своего основания "Омлет" стал символом качества и инноваций в сфере гастрономической доставки. Команда профессиональных поваров и кулинарных экспертов неустанно трудится над тем, чтобы удовлетворить самые изысканные вкусовые предпочтения клиентов.
                    </div>
                    <div class="pb-4">
                        За годы работы "Омлет" укрепил свою репутацию благодаря не только высокому стандарту блюд, но и оперативной и надежной доставке. Клиенты могут полагаться на "Омлет" в любое время дня, зная, что их заказ будет обработан и доставлен в срок.
                    </div>
                    <div class="pb-4">
                        С постоянным стремлением к совершенству и акцентом на индивидуальном обслуживании "Омлет" продолжает завоевывать сердца гурманов, предлагая им уникальные гастрономические впечатления, созданные с любовью к еде и заботе о клиентах.
                    </div>
                    <div class="">
                        Добавьте блюда к заказу из нашего меню, чтобы сделать ваш обед или ужин по-настоящему впечатляющим! Мы готовы порадовать вас свежими и вкусными блюдами, которые точно угодят вашему вкусу. Не упустите шанс попробовать наши лучшие деликатесы прямо сейчас - просто выберите то, что вам нравится, и наслаждайтесь!
                    </div>
                </div>
            </div>
        </section>
    </main>
    <footer class="py-8 bg-white">
        <section id="delivery" class="container mx-auto py-4 px-16">
            <h2 class="text-xl font-semibold uppercase py-8">Условия и способы доставки</h2>
            <div class="flex flex-wrap justify-between">
                <div class="flex flex-col justify-center w-full">
                    <div class="flex flex-col text-left w-full">
                        <div class="text-rose-700 font-semibold text-lg uppercase">СПОСОБЫ ЗАКАЗА</div>
                        <div class="flex flex-row justify-between w-full">
                            <div class="py-2">
                                <div class="text-left text-black text-sm uppercase">По телефону</div>
                                <div class="text-left text-black font-semibold text-lg uppercase">{{ branch.phone }}</div>
                            </div>
                            <div class="py-2">
                                <div class="text-left text-black text-sm uppercase">Ватсапп чат</div>
                                <div class="text-left text-black font-semibold text-lg uppercase">{{ branch.phone }}</div>
                            </div>
                            <!-- <div class="py-2">
                                <div class="text-left text-black text-sm uppercase">Инстаграм директ</div>
                                <div class="text-left text-black font-semibold text-lg uppercase">{{ branch.phone }}</div>
                            </div>
                            <div class="py-2">
                                <div class="text-left text-black text-sm uppercase">На сайте</div>
                                <div class="text-left text-black font-semibold text-lg uppercase">{{ branch.phone }}</div>
                            </div>
                            <div class="py-2">
                                <div class="text-left text-black text-xs uppercase">Телеграм бот</div>
                                <div class="text-left text-black font-semibold text-lg uppercase">{{ branch.phone }}</div>
                            </div> -->
                            <div class="py-2">
                                <div class="text-left text-black text-xs uppercase">Тех поддержка</div>
                                <div class="text-left text-black font-semibold text-lg uppercase">+7 747 180-01-51</div>
                            </div>
                        </div>
                        <div class="flex flex-col justify-between w-full my-16 p-8 bg-yellow-300 rounded-md">
                            <div>
                                <b>Позвони</b>: Просто позвони нам по номеру телефона, и мы поможем тебе сделать заказ!
                            </div>
                            <div>
                                <b>Закажи</b>: Расскажи нам, что ты хочешь поесть, и мы быстро оформим твой заказ.
                            </div>
                            <div>
                                <b>Ешь</b>: Потом просто расслабься и наслаждайся вкусной едой, которую мы привезем тебе прямо к двери!
                            </div>
                        </div>
                        <div class="flex flex-col justify-between w-full my-16 p-8 bg-black text-white rounded-md text-center">
                            <div>
                                <b>Сайт создан и поддерживается вручную</b>, с любовью к своему делу в каждой строчке.
                                По вопросам сотрудничества меня можно найти в
                                <a href="https://t.me/bigblackdot" class="text-blue-500">telegram</a> и
                                <a href="https://instagram.com/alt.zk" class="text-violet-500">instagram</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div>
                    <div class="flex flex-col justify-center items-end">
                        <div class="text-rose-700 font-semibold text-lg uppercase">ДОСТАВКА</div>
                        <div class="py-2">
                            <div class="text-right text-black text-sm uppercase">график работы</div>
                            <div class="text-right text-black font-semibold text-lg uppercase">{{ branch.phone }}</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
    </footer>
</template>

<script>
import ItemContainer from '@/components/ItemContainer.vue'
import TagContainer from '@/components/TagContainer.vue'
import SkeletonLoader from '@/components/skeleton_loading/SkeletonLoader.vue'
import InputSwitch from '@/components/InputSwitch.vue'

export default {
    name: 'HomeView',
    components: {
        ItemContainer,
        TagContainer,
        SkeletonLoader,
        InputSwitch
    },
    data () {
        return {
            branch: {
                id: 1,
                title: 'Павлодар',
                slug: 'pavlodar',
                phone: '+7 747 109-26-82',
                city: { id: 1, name: 'Павлодар' }
            },
            branches: [
                { id: 1, title: 'Павлодар', slug: 'pavlodar', phone: '+7 747 109-26-82', city: { id: 1, name: 'Павлодар' } },
                { id: 2, title: 'Аксу', slug: 'aksu', phone: '+7 747 358-46-36', city: { id: 2, name: 'Аксу' } }
            ],
            combos: null,
            combos_limit: 5,
            tags: null,
            selected_tag: null,
            sub_tags: null,
            selected_sub_tag: null,
            meals: null,
            meals_meta: null,
            meals_limit: 10,
            meals_offset: 0,
            basket: [],
            emoji: ['😋', '😉', '😀', '😃', '😄', '😁', '😆', '😅', '😊', '😇', '🙂', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '❤️', '🧡', '💛', '💚', '💙', '💜', '🖤'],
            basket_stage: 1,
            user: {
                phone: null
            },
            address: {
                street: '',
                building: '',
                appartment: '',
                entrance: '',
                floor: '',
                has_intercom: false,
                details: ''
            },
            payment: {
                cash: 0,
                transfer: 0,
                promocode: ''
            },
            sendingError: '',
            is_sendingorder: false,
            new_order_id: null
        }
    },
    methods: {
        setBranch (branch) {
            this.branch = branch
            this.$router.push({ name: 'home', params: { branch: branch.slug } })
            // this.getTags()
            // this.getCombos()
        },
        getCombos () {
            this.combos_limit += 5
            const response = this.getServerData('get_combos', [this.combos_limit, this.branch.id])
            response.then(response => {
                if (response.data != null) {
                    this.combos = response.data
                }
            })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .finally(() => (this.is_loaded = true))
        },
        getTags () {
            const response = this.getServerData('get_tags', [this.branch.id])
            response.then(response => {
                if (response.data != null) {
                    this.tags = response.data
                    this.selected_tag = this.tags[0]

                    this.getSubTags()
                }
            })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .finally(() => (this.is_loaded = true))
        },
        getSubTags () {
            this.sub_tags = null
            const response = this.getServerData('get_sub_tags', [this.selected_tag.id])
            response.then(response => {
                if (response.data != null) {
                    this.sub_tags = response.data
                    // if (this.sub_tags.length > 0) {
                    //     this.selected_sub_tag = this.sub_tags[0]
                    //     this.getMealsBySubTag()
                    // } else {
                    this.selected_sub_tag = { id: 'all' }
                    this.meals_limit = 10
                    this.getMealsByTag()
                    // }
                }
            })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .finally(() => (this.is_loaded = true))
        },
        getMoreMealsByTag () {
            this.meals_limit += 10
            this.getMealsByTag()
        },
        getMealsByTag () {
            const response = this.getServerData('get_meals_by_tag', [this.selected_tag.id, this.meals_limit, this.meals_offset])
            response.then(response => {
                if (response.data != null) {
                    this.meals = response.data.data
                    this.meals_meta = response.data.meta
                }
            })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .finally(() => (this.is_loaded = true))
        },
        getMealsBySubTag () {
            const response = this.getServerData('get_meals_by_sub_tag', [this.selected_sub_tag.id])
            response.then(response => {
                if (response.data != null) {
                    this.meals = response.data
                    // this.selected_tag = this.tags[0]
                }
            })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .finally(() => (this.is_loaded = true))
        },
        selectTag (tagId) {
            // this.selected_tag_id = tagId
            // this.selected_tag_meals = this.tags.filter(this.tags[tagId].main_tag_meals
            const findCurrentTag = this.tags.filter((tag) => tag.id === tagId)
            this.selected_tag = findCurrentTag[0]
            this.selected_sub_tag = { id: 'all' }
            // this.selected_tag_meals = findCurrentTag[0].main_tag_meals
            // this.selected_tag_meals = findCurrentTag[0].main_tag_meals
            this.getSubTags()
        },
        selectSubTag (tagId) {
            this.selected_sub_tag = { id: 'all' }

            if (tagId !== 'all') {
                const findCurrentTag = this.sub_tags.filter((tag) => tag.id === tagId)
                this.selected_sub_tag = findCurrentTag[0]
                this.getMealsBySubTag()
            } else {
                this.getMealsByTag()
            }
        },
        sendOrder () {
            // if (this.$store.getters.basketSumm === 0) { this.current_sector = 0; return }
            if (!this.user.phone) { this.$refs.user_phone.focus(); return }
            if (!this.address.street) { return this.$refs.address_street.focus() }
            if (!this.address.building) { return this.$refs.address_building.focus() }

            this.is_sendingroder = true
            this.basket_stage = 3

            var params = { branch_id: this.branch.id, status_id: 2 }

            params.extra_info = {
                order_source: 'omelette.kz'
            }

            params.customer = {}
            params.address = {
                country: {},
                city: {},
                street: {},
                building: {}
            }
            params.date = {}
            params.items = {}
            params.payment = {}

            params.customer.id = ''
            params.customer.phone = this.user.phone

            // params.address.raw                   = ac + ', ' + as + ', ' + ah + '-' + af + ' п' + ap + ' э' + afl;
            params.address.country.name = 'Казахстан'
            params.address.city.name = this.branch.city?.name
            params.address.street.name = this.address.street
            params.address.building.number = this.address.building
            params.address.building.type = ''
            params.address.coords = ''
            params.address.appartment_number = this.address.appartment
            params.address.floor = this.address.floor
            params.address.entrance = this.address.entrance
            params.address.has_intercom = this.address.has_intercom ? 1 : 0
            params.address.details = this.address.details

            // params.date.is_preorder              = 0;
            params.date.date = 'Сегодня'
            params.date.time = 'Сейчас'
            params.date.time_est = '60:80'

            params.items = this.$store.getters.basketItems

            // params.payment.summ_meals_in_list    = 0;
            // params.payment.summ_discounted_meals = 0;
            // params.payment.bonuses_to_minus      = 0;
            // params.payment.bonuses_to_add        = 0;
            params.payment.upcount = 0
            params.payment.upcount_comment = ''
            params.payment.discount = 0
            params.payment.discount_comment = ''
            params.payment.delivery_cost = ''
            params.payment.transfer = this.payment.transfer
            params.payment.cash = this.payment.cash

            params.payment.promocode = this.payment.promocode

            this.postServerData('store_order', { data: params })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.basket_stage = 4
                        this.is_sendingorder = false
                        this.new_order_id = res.data.order_id
                    }
                })
                // .finally(() => {
                //     this.basket_stage = 4
                //     this.is_sendingorder = false
                // })

            // axios
            //     .post(api_route('order_store'), params)
            //     .then((res)=>{
            //         if(res.data){
            //             if(res.data.code != 200){
            //                 this.sendingError = res.data.message
            //             } else
            //             if(res.data.code == 200){
            //                 this.sendingError = res.data.message
            //                 this.$store.commit('clearBasket')
            //                 this.basketMeals = this.$store.getters.basketItems
            //                 this.current_sector = 4
            //             }
            //         }
            //     })
            //     .finally(()=> this.is_sendingorder = false)
        }
    },
    watch: {
        '$route' (to, from) {
            if (to.name === this.$route.name) {
                this.getTags()
                this.getCombos()
            }
        }
    },
    mounted () {
        const abranch = this.$route.params.branch
        if (abranch) {
            const findBranch = this.branches.filter((item) => item.slug === abranch)
            this.branch = findBranch[0]
        } else {
            this.branch = this.branches[0]
        }

        // const section = this.$route.params.section
        // switch (section) {
        // case 'menu':
        //     this.$refs.menu.scrollIntoView({ behavior: 'smooth' })
        //     break
        // }

        // const sections = document.querySelectorAll('.nav-section')
        // const options = {
        //     threshold: 0.7
        // }

        // function observeHandler (entries, observer, router) {
        //     entries.forEach(entry => {
        //         // console.log(entry)
        //         // document.location.href = entry.target.outerText
        //         if (entry.isIntersecting) {
        //             document.title = entry.target.outerText
        //             var path = entry.target.attributes.path.nodeValue
        //             router.push({ path: path })
        //             // console.log(entry)
        //             // document.location.href = entry.target.attributes.title.nodeValue
        //             // console.log(entry.target)
        //         }
        //     })
        // }

        // const observer = new IntersectionObserver((entries, observer) => { observeHandler(entries, observer, this.$router) }, options)

        // sections.forEach(section => {
        //     observer.observe(section)
        // })

        this.getTags()
        this.getCombos()
    }
}
</script>
<style scoped>
.branches div:first-child {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.branches div:last-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}
</style>
