<template>
    <div class="rounded-full w-16 p-1 flex transition-all cursor-pointer"
        @click="toggleActive()"
        :class="[ isActive ? 'bg-green-500' : 'bg-gray-200' ]">
        <div class="rounded-full h-6 bg-white shadow-md transition-all"
            :class="classes"></div>
    </div>
</template>
<script>
export default {
    emits: ['update:modelValue'],
    props: ['modelValue'],
    data () {
        return {
            isActive: this.modelValue,
            isBusy: false
        }
    },
    methods: {
        setIsActive (newState) {
            this.isBusy = true
            setTimeout(
                () => {
                    this.isBusy = false
                    this.isActive = newState
                    this.$emit('update:modelValue', newState)
                }, 100)
        },
        toggleActive () {
            this.isActive = this.setIsActive(!this.isActive)
        }
    },
    computed: {
        classes () {
            let classes = ''
            if (this.isActive) {
                classes += ' ml-8'
            } else {
                classes += ' ml-0'
            }
            if (this.isBusy) {
                classes += ' w-full'
            } else {
                classes += ' w-6'
            }
            return classes
        }
    }

}
</script>
<style scoped>
</style>
