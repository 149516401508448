import axios from 'axios'

export default {
    data: () => {
        const baseUrl = 'https://api.breaktime.kz/api/web/v2/omelette'
        return {
            routes: {
                login: baseUrl + '/?a=login',
                get_branches_data: baseUrl + '/branches/all',
                get_combos: baseUrl + '/combos/top/{$0}/{$1}',
                get_tags: baseUrl + '/tags/all/{$0}',
                get_sub_tags: baseUrl + '/tags/by_parent/{$0}',
                get_meals_by_tag: baseUrl + '/meals/by_tag/{$0}/{$1}/{$2}',
                get_meals_by_sub_tag: baseUrl + '/meals/by_child_tag/{$0}',
                store_order: baseUrl + '/orders/store'
            }
        }
    },
    methods: {
        api_route: function (routeName, params = null) {
            let routeStr = this.routes[routeName]
            if (params) {
                params.forEach((value, index) => {
                    routeStr = routeStr.replace('{$' + index.toString() + '}', value)
                })
            }
            return routeStr
        },
        getServerData: function (routeName, params = null) {
            const baseUrl = this.api_route(routeName, params)
            return axios.get(baseUrl)
        },
        postServerData (routeName, { params = null, data }) {
            const baseUrl = this.api_route(routeName, params)
            return axios.post(baseUrl, data)
        }
    }
}
