import { createStore } from 'vuex'

export default createStore({
    state: {
        // состояния
        user: null,
        address: null,
        basketItems: [],
        branch:
            {
                id: 1,
                city: {
                    id: 1,
                    name: 'Павлодар'
                }
            },
        branches: [
            {
                id: 1,
                city: {
                    id: 1,
                    name: 'Павлодар'
                }
            },
            {
                id: 2,
                city: {
                    id: 2,
                    name: 'Аксу'
                }
            }
        ]
    },
    getters: {
        // получить состояния
        basketItems (state) {
            return state.basketItems
        },
        countItemInBasket: (state) => (mealId, isCombo) => {
            return state.basketItems.find(item => item.id === mealId && item.is_combo === isCombo)
        },
        basketSumm (state) {
            let summ = 0
            state.basketItems.forEach(item => {
                summ += item.amount * item.price
            })
            return summ
        },
        getBranch (state) {
            return state.branch
        },
        getBranches (state) {
            return state.branches
        }
    },
    mutations: {
        // функции в которых меняем состояние
        increaseAmount (state, item) {
            return item.amount++
        },
        decreaseAmount (state, _item) {
            if (_item.amount > 1) return _item.amount--
            return state.basketItems.splice(state.basketItems.findIndex(item => item === _item), 1)
        },
        removeFromBasket (state, payload) {
            const meal = payload.item
            const isCombo = payload.is_combo
            const details = payload.details
            const _bmeal = state.basketItems.find(item => item.id === meal.id && item.is_combo === isCombo && item.details === details)
            if (_bmeal && _bmeal.amount > 1) return _bmeal.amount--
            return state.basketItems.splice(state.basketItems.findIndex(item => item.id === meal.id && item.is_combo === isCombo && item.details === details), 1)
        },
        addToBasket (state, payload) {
            const _item = {
                id: payload.item.id,
                name: payload.item.name,
                is_combo: payload.is_combo,
                is_bonus: 0,
                amount: 1,
                price: payload.item.price,
                details: payload.details || '',
                thumb: payload.item.main_image
            }
            const _bmeal = state.basketItems.find(item => item.id === _item.id && item.is_combo === _item.is_combo && item.details === _item.details)
            if (_bmeal) return _bmeal.amount++
            return state.basketItems.push(_item)
        },
        clearBasket (state) {
            state.basketItems = []
        },
        loginUser () {
        },
        setBranch (state, _branch) {
            state.basketItems = []
            state.branch = _branch
        }
    },
    actions: {
    },
    modules: {
    }
})
