<template lang="">
    <div>
        <div class="rounded-3xl rounded-t-md shadow-md bg-white pt-[120px] mt-[120px]">
            <div class="relative mt-[-240px] overflow-hidden w-full flex justify-center">
                <img v-if="is_loading === false" :src="main_image_src" class="rounded-full aspect-square w-11/12 bg-gray-300" :alt="item?.name"/>
                <SkeletonLoader v-else class="rounded-full aspect-square w-11/12" />
            </div>
            <div class="px-6 pb-4">
                <div class="h-14 flex items-center">
                    <div v-if="is_loading === false" class="text-left text-lg leading-5">{{ item?.name }}</div>
                    <SkeletonLoader v-else class="rounded-md w-1/2 h-6 my-2" />
                </div>
                <div class="flex justify-between items-center text-md">
                    <div class="w-full flex justify-start">
                        <div v-if="is_loading === false" class="font-semibold">{{ item?.price }} <sup>₸</sup></div>
                        <SkeletonLoader v-else class="rounded-md w-2/3 h-6 my-2" />
                    </div>
                    <div class="w-full flex justify-end">
                        <div v-if="!this.$store.getters.countItemInBasket(item?.id, is_combo)" class="flex w-full text-center">
                            <div class="p-2 bg-yellow-100 border border-yellow-100 rounded-lg w-full cursor-pointer" @click="addMealToCart(item)"><nobr>{{ emoji[Math.floor(Math.random()*emoji.length)] }} заказать</nobr></div>
                        </div>
                        <div v-else class="flex w-full text-2xl">
                            <div class="w-1/3 py-1 px-2 bg-gray-100 text-red-700 font-semibold text-center rounded-tl-lg rounded-bl-lg cursor-pointer" @click="removeMealFromCart(item)">-</div>
                            <div class="w-1/3 py-1 px-2 text-center border-t border-b" :class="{'bounce animated': animated}" @animationend="animated = false">{{ this.$store.getters.countItemInBasket(item?.id, is_combo).amount }}</div>
                            <div class="w-1/3 py-1 px-2 bg-green-100  text-green-700 font-semibold text-center rounded-tr-lg rounded-br-lg cursor-pointer" @click="addMealToCart(item)">+</div>
                        </div>
                        <!-- <button v-if="is_loading === false" class="rounded-md bg-amber-500 text-white px-4 py-1" @click="aaddToBasket(item)">Заказать</button> -->
                        <!-- <SkeletonLoader v-else class="rounded-md w-1/2 h-6 my-2" /> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SkeletonLoader from '@/components/skeleton_loading/SkeletonLoader.vue'

export default {
    name: 'ItemContainer',
    props: {
        item: {
            type: Object,
            default: null
        },
        is_loading: {
            type: Boolean,
            default: false
        },
        is_combo: {
            type: Boolean,
            default: false
        },
        addToBasket: null
    },
    components: {
        SkeletonLoader
    },
    data () {
        return {
            animated: false,
            emoji: ['😋', '😉', '😀', '😃', '😄', '😁', '😆', '😅', '😊', '😇', '🙂', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '❤️', '🧡', '💛', '💚', '💙', '💜', '🖤']
        }
    },
    computed: {
        main_image_src () {
            if (this.item !== null) {
                if (this.item?.thumb?.url !== null) return this.item?.thumb?.url
                else if (this.item?.main_image?.url !== null) return this.item?.main_image?.url
            }
            return 'https://pp.userapi.com/c844320/v844320046/1da48a/UqcT5Mqn7sg.jpg'
        }
    },
    methods: {
        addMealToCart (item) {
            this.$root.increasing = true
            this.$store.commit('addToBasket', { item, is_combo: this.is_combo, details: '' })
        },
        removeMealFromCart (item) {
            this.$root.decreasing = true
            this.$store.commit('removeFromBasket', { item, is_combo: this.is_combo, details: '' })
        }
    }
}
</script>
<style scoped>
</style>
