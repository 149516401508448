<template lang="">
    <div class="p-2 rounded-2xl border-amber-500 border shadow-lg">
        <div class="aspect-square h-20 flex items-center justify-center text-4xl">
            <!-- <img src="{{ item.emoji }}" class="" /> -->
            {{ item.emoji }}
        </div>
        <div class="h-6 px-2 text-center text-md">{{ item.name }}</div>
    </div>
</template>
<script>
export default {
    name: 'ItemContainer',
    props: {
        item: Object
    },
    data () {
        return {
        }
    }
}
</script>
<style scoped>
</style>
