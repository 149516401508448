<template>
    <div class="gray"></div>
</template>
<script>
export default {
    name: 'SkeletonLoader',
    props: {
        outerClass: {
            type: String,
            default: ''
        }
    }
}
</script>
<style scoped>
.gray {
    background: linear-gradient(90deg, #888, #888,#888, #ddd, #888, #888, #888);
    background-size: 800% 800%;
    animation: courusel 2.5s linear infinite;
    animation-delay: 0s;
}
@keyframes courusel {
    0% {
        background-position: 100% 50%;
        opacity: 1
    }
    50% {
        background-position: 50% 50%;
        opacity: .7
    }
    100% {
        background-position: 0% 50%;
        opacity: 1
    }
}
@keyframes tides {
    0% {
        background-position: 0% 50%;
        opacity: 1
    }
    50% {
        background-position: 100% 50%;
        opacity: .7
    }
    100% {
        background-position: 0% 50%;
        opacity: 1
    }
}
</style>
